<template>
  <Details
    title="profile"
    resource="profiles"
    :base-path="basePath"
    data-provider="$raaDataProvider"
    route-id-param="profileId"
  >
    <template v-slot:side-panel>
      <DetailsSidePanel entity-title="profile"></DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <ProfileForm
        :initialValues="detailsProps.record"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import Details from "@/components/auth/details/Details";
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import ProfileForm from "@/views/auth/raa-devtools/profiles/ProfileForm";

  export default {
    name: "ProfileEdit",
    components: {ProfileForm, Details, DetailsSidePanel},
    mixins: [ModalNavigation],
    computed: {
      basePath: function () {
        return this.$route.name.replace('edit', 'index');
      },
      profileId() {
        return this.$route.params.profileId;
      },
    },
  }
</script>

<style scoped>

</style>
